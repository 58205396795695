<template>
  <div>
    <b-card>
      <b-card-title title="Configuración de documentos"></b-card-title>
      <b-card-body>
        <b-row class="mb-1">
          <b-form-group class="ml-1" label="AÑO" label-for="basicInput">
            <b-form-select v-model="year" :options="yearOptions">
            </b-form-select>
          </b-form-group>
          <b-form-group class="mt-2">
            <b-button class="mx-3" variant="primary" @click="filterByYear">
              <feather-icon icon="SearchIcon" /> Filtrar
            </b-button>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col>
            <b-spinner
              v-if="isLoading"
              variant="success"
              label="Spinning"
            ></b-spinner>
            <b-table
              v-else
              responsive
              :fields="fields"
              :items="items"
              sticky-header
            >
              <template #cell(january)="data">
                <b-form-checkbox
                  v-model="data.item.january"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(febrary)="data">
                <b-form-checkbox
                  v-model="data.item.febrary"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(march)="data">
                <b-form-checkbox
                  v-model="data.item.march"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(april)="data">
                <b-form-checkbox
                  v-model="data.item.april"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(may)="data">
                <b-form-checkbox
                  v-model="data.item.may"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(june)="data">
                <b-form-checkbox
                  v-model="data.item.june"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(july)="data">
                <b-form-checkbox
                  v-model="data.item.july"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(august)="data">
                <b-form-checkbox
                  v-model="data.item.august"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(september)="data">
                <b-form-checkbox
                  v-model="data.item.september"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(october)="data">
                <b-form-checkbox
                  v-model="data.item.october"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(november)="data">
                <b-form-checkbox
                  v-model="data.item.november"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(december)="data">
                <b-form-checkbox
                  v-model="data.item.december"
                  switch
                  :disabled = "disableEdit"
                ></b-form-checkbox>
              </template>
              <template #cell(document)="data">
                {{ data.item.document }}
              </template>
              <template v-if="userType !='AuditorCarbono'" #cell()="data">
                <b-row>
                  <b-button @click="removeDocument(data.item)" variant="danger"
                    >Eliminar</b-button
                  >
                </b-row>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-button v-if="userType !='AuditorCarbono' " @click="openModalNewDocument" variant="primary"
            >+ Agregar Documento</b-button
          >
          <b-button v-if="userType !='AuditorCarbono'" @click="sendChanges" variant="warning" class="mx-3"
            >Guardar</b-button
          >
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      title="Nuevo Documento"
      ref="add-new-document-modal"
      centered
      hide-footer
      @hidden="closeModalNewDocument"
    >
      <b-col>
        <b-form-group label="Nombre Documento" label-for="basicInput">
          <b-form-input
            v-model="newNameDocument"
            placeholder="Digite el Nombre..."
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Descripción" label-for="basicInput">
          <b-form-input
            v-model="newDescriptionDocument"
            placeholder="Descripción..."
          ></b-form-input>
        </b-form-group>
        <b-row class="mt-3 mb-1">
          <b-button
            @click="closeModalNewDocument"
            variant="danger"
            class="w-25 ml-1"
            >Cancelar</b-button
          >
          <b-button @click="newDocument" variant="success" class="mx-2 w-25"
            >Enviar</b-button
          >
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>
<script>
import {
  BLink,
  BButton,
  BImg,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BTable,
  BAlert,
  BBadge,
  BFormFile,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BCard,
  BCardTitle,
  BCardText,
  BCardBody,
  BCalendar,
  BDropdown,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { getUserData } from "@/auth/utils";
import departamentos from "../../assets/departamentos-municipios.json";
import axios from "@axios";
import axiosIns from "@/libs/axios";
import { activated } from "vue-echarts";
import { title } from "echarts/lib/theme/dark";

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormFile,
    BTable,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BCardBody,
    BCard,
    BCardTitle,
    BCalendar,
    BDropdown,
    Cleave,
  },
  data() {
    return {
      isLoading: false,
      thisYear: null,
      newNameDocument: null,
      newDescriptionDocument: null,
      selectMode: "single",
      yearOptions: [2021, 2022, 2023, 2024],
      year: new Date().getFullYear(),
      fields: [
        { key: "document", label: "Documento" },
        { key: "january", label: "Enero" },
        { key: "febrary", label: "Febrero" },
        { key: "march", label: "Marzo" },
        { key: "april", label: "Abril" },
        { key: "may", label: "Mayo" },
        { key: "june", label: "Junio" },
        { key: "july", label: "Julio" },
        { key: "august", label: "Agosto" },
        { key: "september", label: "Septiembre" },
        { key: "october", label: "Octubre" },
        { key: "november", label: "Noviembre" },
        { key: "december", label: "Diciembre" },
        "   ",
      ],
      items: [],
      userType: null,
      disableEdit: false,
    };
  },
  created() {
    var userData = getUserData();
    this.userType = userData.userType;
    if (this.userType == 'AuditorCarbono') {
      this.disableEdit = true;
    }
    this.loadAllDocuments();
  },
  methods: {
    loadAllDocuments() {
      this.thisYear = new Date().getFullYear();
      axios
        .get("/CarbonFarm/LoadDocumentsByYear", {
          params: { year: this.thisYear },
        })
        .then((response) => {
          this.items = response.data;
        });
    },
    sendChanges() {
      var newItems = this.items;
      axios
        .post("/CarbonFarm/SettingDocumentsDates", newItems)
        .then((response) => {
          this.$bvToast.toast("Cambios realizados", {
            title: "Ok",
            variant: "success",
            solid: true,
          });
          this.filterByYear();
        });
    },
    removeDocument(removedDocument) {
      this.isLoading = true;
      axios
        .get("/CarbonFarm/RemoveDocument", {
          params: { removeID: removedDocument.documentsCalendarID },
        })
        .then((response) => {
          if (response.data == true) {
            this.$bvToast.toast("Se eliminó el documento", {
              title: "OK",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(
              "No se puede eliminar el documento. No está en la base de datos o un productor ya hizo una carga de este documento",
              {
                title: "Error",
                variant: "danger",
                solid: true,
              }
            );
          }
          this.filterByYear();
          this.isLoading = false;
        });
    },
    filterByYear() {
      const filteredYear = this.year;
      this.isLoading = true;
      axios
        .get("/CarbonFarm/LoadDocumentsByYear", {
          params: { year: filteredYear },
        })
        .then((response) => {
          this.items = response.data;
          this.isLoading = false;
        });
    },
    openModalNewDocument() {
      this.$refs["add-new-document-modal"].show();
    },
    newDocument() {
      const descriptionND = this.newDescriptionDocument;
      const nameND = this.newNameDocument;
      this.$refs["add-new-document-modal"].hide();
      axios
        .get("/CarbonFarm/AddNewDocumentForFarms", {
          params: { document: nameND, description: descriptionND },
        })
        .then((response) => {
          this.$bvToast.toast("Se guardó el documento", {
            title: "Ok",
            variant: "success",
            solid: true,
          });
          this.loadAllDocuments();
        });
    },
    closeModalNewDocument() {
      this.newDescriptionDocument = null;
      this.newNameDocument = null;
      this.$refs["add-new-document-modal"].hide();
    },
  },
};
</script>
<style></style>
